import React from "react";
import FinalProject from "../assets/Projecten/laassaraVideo25.mp4";

const ProjectSection = () => {
  return (
    <section className="bg-white sm:py-[65px] pt-12 pb-12 headerSection">
      <div className="container mx-auto flex flex-col-reverse sm:flex-row-reverse md:mt-7 gap-6 sm:gap-0">
        
        {/* Left Text Section */}
        <div className="justify-center space-y-8 text-center md:text-left px-15 w-full sm:w-1/2 pt-3">
          <h1 className="text-3xl md:text-4xl text-center sm:text-left font-bold text-gray-800">
            Onze Projecten
          </h1>
          <p className="text-gray-600 text-md text-wrap pr-0 sm:pr-20 mx-2 sm:mx-0">
          Kijk, dit is waar de Laassara Foundation voor staat!
            <br />
            <br />
            We hebben zes jonge mannen en één jonge meid die wij met trots sponsoren. Deze kinderen brengen woensdagen en donderdagen door bij hun ouders, en de overige dagen (vrijdag, zaterdag, zondag, maandag en dinsdag) volgen ze onderwijs op een Koranschool. 
            <br />
            <br />
            Daar krijgen ze na hun lessen op de basisschool in de middag en avond intensieve Koranlessen. Wij sponsoren deze jongeren van A tot Z.
<br />
<br />

Op dit moment zijn ze bezig met de 9de hizb en met de wil van Allah hopen we dat zij na een aantal jaren de hele Koran uit hun hoofd leren.Wilt u hieraan bijdragen? 


          </p>
          <a
            href="/projecten"
            className="bg-softBlue text-white px-6 py-3 rounded-lg text-lg hover:scale-105 hover:shadow-lg transition duration-300 inline-block mt-3"
          >
            Lees meer
          </a>
        </div>

        {/* Right Video Section */}
        <div className="flex bg-transparent md:pr-24">
          <video
            controls
            className="w-[700px] max-w-[800px] md:pt-6 h-96 rounded-none mx-auto md:rounded-lg"
          >
            <source src={FinalProject} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
